body {
	font-family: "Google Sans Regular";
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: "Google Sans Regular"
}

h1, h2, h3, h4 {
	font-family: 'Google Sans Bold' !important;
}

h5, h6 {
	font-family: 'Google Sans Medium' !important;
}

@font-face {
	font-family: "Google Sans Bold";
	src:
		local("Google Sans Bold"),
		url("./assests/fonts/GoogleSans-Bold.ttf") format("woff");
}
@font-face {
	font-family: "Google Sans Medium";
	src:
		local("Google Sans Medium"),
		url("./assests/fonts/GoogleSans-Medium.ttf") format("woff");
}
@font-face {
	font-family: "Google Sans Regular";
	src:
		local("Google Sans Regular"),
		url("./assests/fonts/GoogleSans-Regular.ttf") format("woff");
}
